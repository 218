import React from 'react';
import {withStyles} from '@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles';
import {withDefaultStyleParams} from '@wix/wixstores-client-common-components/dist/es/src/outOfIframes/defaultStyleParams/withDefaultStyleParams';
import {getStyleParamsWithDefaultsFunc} from './getStyleParamsWithDefaultsFunc';
import {withGlobalsProvider, initContext, Strategy} from './globalPropsContext';

export interface IGetWrappedComponentOptions {
  Component: React.ComponentType;
  globalPropsStrategy: Strategy<any>;
  withStyleStrategy: typeof withStyles;
  flowEditor?: boolean;
  sliderGallery?: boolean;
}

export function getWrappedComponent({
  Component,
  globalPropsStrategy,
  withStyleStrategy,
  flowEditor,
  sliderGallery,
}: IGetWrappedComponentOptions) {
  initContext(globalPropsStrategy);
  let wrappedComponent;
  wrappedComponent = withStyleStrategy(withGlobalsProvider(Component, flowEditor, sliderGallery), {
    strictMode: true,
  });
  wrappedComponent = withDefaultStyleParams(wrappedComponent, ({host}) => getStyleParamsWithDefaultsFunc(host));

  return wrappedComponent;
}
