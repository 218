/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable @typescript-eslint/no-require-imports */
const {sliderGlobalPropsStrategy} = require('./sliderGlobalStrategy');
const {withStyles: ws} = require('@wix/native-components-infra/dist/es/src/HOC/withStyles/withStyles');
const {
  withSentryErrorBoundary: sentry,
} = require('@wix/native-components-infra/dist/es/src/HOC/sentryErrorBoundary/sentryErrorBoundary');
const {SLIDER_GALLERY_DSN: DSN} = require('@wix/wixstores-client-storefront-sdk/dist/es/src/viewer-script/sentryConf');
const {getWrappedComponent: get} = require('../appConfig');
const {SliderGallery} = require('./components/SliderGallery/SliderGallery');

const sliderGallery = get({
  Component: SliderGallery,
  withStyleStrategy: ws,
  globalPropsStrategy: sliderGlobalPropsStrategy,
});

module.exports = {
  default: {
    component: sentry(sliderGallery, {
      dsn: DSN,
      config: {environment: 'Native Component'},
    }),
  },
};
